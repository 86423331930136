
.app {
  width: 100%;
  overflow-x: hidden;
}

.app-header {
  padding: 30px 60px 30px;
  max-width: 380px;
  margin: 0 auto;
}
.app-logo {

}
.stanimhouseg {
  transform-origin: 156px 255px;
}
.stanimhouse {
  fill-rule:evenodd;clip-rule:evenodd;


  fill: #fff;
}
.stanim1 {
  fill-rule:evenodd;clip-rule:evenodd;

}
.stanim2 {
  fill:#FFFFFF;
}
.loaded .curveanim {
}
.loaded .leftrightanim {
  
}
.thewords {
  
  animation-duration: 0.75s;
  animation-delay: 3.1s;
  animation-fill-mode: forwards;
  transform-origin: center 280px;
}
.flipme {transform: rotate(180deg); transform-origin: center center;}
.mirrorme { transform: rotate(180deg) scale(1, -1); transform-origin: center center;}

@keyframes big {
  from {
    transform: scale3d(0, 0, 0);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes textfadebig {
  from {
    transform: scale(0.8); opacity: 0;
  }
  to {
    transform: scale(1); opacity: 1;
  }
}

.video-section {
  width: 100%;
  font-size: 0;
}
.video-section img {
  max-width: 100%;
  height: auto;
}
.video-wrap {
  padding-top: 56.25%;
  height: 0;
  width: 100%;
  position: relative;
}
.video-wrap iframe {
  position:absolute;top:0;left:0;width:100%;height:100%;
}

.info-section {
  background-color: rgba(0,0,0,0.25);
  padding: 48px 15px 50px;
}
.info-section h2 {
  margin: 0 0 24px;
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
}
.info-section p {
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 1.25em;
}
.info-section p:last-child {
  margin: 0;
}

.image-section,
.image-section-contained {
  font-size: 0;
}
.image-section img,
.image-section-contained img {
  max-width: 100%;
  height: auto;
}

.siteplan-section {
  background-color: #fff;
  padding: 40px 5px 50px;

}
.siteplan-section h2 {
  margin: 0 0 12px;
  text-align: center;
  display: block;
  padding: 0 15px;
  font-size: 30px;
  font-weight: 500;
  color: #222;
  line-height: 1.2;
}
.siteplan-section img {
  max-width: 100%;
  height: auto;
}


.form-section {
  background-color: rgba(0,0,0,0.25);
  padding: 50px 15px;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

}
.form-section h2 {
  margin: 0 0 1em;
  font-weight: 500;
  color: #fff;
  text-align: center;
  font-size: 30px;
  line-height: 1.2;
}
.form-section label {
  display: block;
  width: 100%;
  margin-bottom: 22px;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
.form-section label span {
  font-size: 18px;
  color: #fff;
  display: block;
  line-height: 1.3;
  margin-bottom: 8px;
}
.form-section input,
.form-section textarea {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  color: #000;
  font-size: 17px;
  line-height: 22px;
  padding: 9px 10px;
  width: 100%;
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}
.form-section textarea {
  min-height: 120px;
}
.btn {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  
  background-color: #346ebf;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 15px 30px;
  min-width: 150px;

  cursor: pointer;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  text-decoration: none;
  display: inline-block;
}
.form-section button {

  margin-bottom: 5px;

  margin-top: 10px;
  width: 100%;
}
.contact-btn { 
  margin-top: 6px;
}
.form-section form.submitted {
   display: none;
}
.form-section form.submitted + #form-message {
  display: block;
}
#form-message {
  text-align: center;
    color: #fff;
    font-size: 17px;
}

@media (min-width: 600px) {
  .app {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .app-header {
    width: 320px;
    order: 0;
  }
  .info-section {
    order: 2;
    width: 100%;
    padding: 50px;
  }
  .video-section {
    order: 1;
  }
  .image-section {
    order: 3;
  }
  .siteplan-section {
    order: 4;
    padding: 40px 10px 50px;
  }
  .image-section-contained {
    order: 5;
  }
  .form-section {
    order: 6;
    width: 100%;
    padding: 48px 50px 50px;
  }
}
@media (min-width: 768px) {

  .info-section {
    padding: 66px 7.5% 70px;
  }
  .info-section h2 {
    font-size: 36px;
    margin: 0 0 30px;
  }
  .info-section p {
    font-size: 20px;
  }
  .form-section {
    padding: 66px 7.5% 70px;
  }
  .siteplan-section h2 {
    font-size: 36px;
  }
  .form-section h2 {
    font-size: 36px;
  }
  .form-section form {
    max-width: 500px;
    margin: 0 auto;
  }
}
@media (min-width: 980px) {
  .app {
  
  }
  .app-header {
    padding: 25px 20px 40px 60px;
    width: 340px;
  }
  .info-section {
    order: 1;
    width: calc(100% - 340px);
    background: transparent;
    padding-left: 6.5%;
    padding-right: 6.5%;
    padding-top: 62px;
    padding-bottom: 76px;
  }
  .video-section {
    order: 2;
    padding: 50px 40px;
    background-color: rgba(0,0,0,0.25);
  }
  .image-section-contained {
    padding: 50px 40px;
  }
  .siteplan-section {
    order: 3;
    width: 50%;
    padding-left: 10px;
    padding-right: 15px;
    padding-top: 45px;
    padding-bottom: 50px;
  }
  .image-section {
    order: 4;
    width: 50%;
    align-self: stretch;
  }
  .image-section img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    object-position: center;
  }
  .form-section form {
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
  }
  .form-section label {
    width: calc(50% - 15px);
  }
  .form-section label.full-width {
    width: 100%;
  }
  .form-section button {
    transition: all 200ms ease-out;
    width: auto;
    margin: 10px auto 5px;
    min-width: 200px;
  }
  .form-section button:hover {
    background-color: #3e84e5;
    
  }
}

@media (min-width: 1200px) {
  .app-header {
    width: 340px;
    margin-left: calc((100% - 1200px ) / 2)
  }
  .info-section {
    width: 860px;
    margin-right: calc((100% - 1200px ) / 2);
    padding-left: 80px;
    padding-right: 80px;
  }
  .form-section {
    padding: 92px 7.5% 100px;
  }
  .form-section h2 {
    margin-bottom: 1.6em;
  }
  .form-section label {
    margin-bottom: 24px;
  }
  .form-section label span {
    font-size: 20px;
  }
  .form-section input {
    font-size: 20px;
    padding: 13px 15px;
  }
  .form-section button {
    font-size: 22px;
    min-width: 220px;
    padding: 16px;
    margin-top: 15px;
  }
  
}
@media (min-width: 1280px) {
  .siteplan-section {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;
    padding-bottom: 60px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .siteplan-section img {
    max-width: 888px;
    height: auto;
    width: 100%;
  }
}
@media (min-width: 1360px) {
  .video-section,
  .image-section-contained {
    padding-left: calc((100% - 1280px) / 2);
    padding-right: calc((100% - 1280px) / 2);
    padding-top: 4%;
    padding-bottom: 4%;
  }
  .info-section {
    padding-top: 72px;
    padding-bottom: 90px;
  }
  
}